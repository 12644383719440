.background_right-content_container {
  position: relative;
  margin: 0px 0px;
  top: 6%;
}
.trading-bot-image {
  width: 262px;
  height: 250px;
}
@media screen and (min-width: 1000px) {
  .background_right-content_container {
    top: 10%;
    margin: 10px 0px;
  }
  .trading-bot-image {
    width: 262px;
    height: 250px;
  }
}
@media screen and (min-width: 1000px) {
  .background_right-content_container {
    top: 20%;
    margin: 40px 0px;
  }
  .trading-bot-image {
    width: 420px;
    height: 400px;
  }
}
