.background_left-content_container {
  /* Auto Layout */
  display: flex;
  flex-direction: column;

  padding: 0px;
  position: relative;
  margin: 40px 0px;
  height: 27%;
  left: 0%;
}

.background_left-content_build-tagline,
.background_left-content_no-code-tagline {
  position: relative;
  margin-left: 10%;
  padding: 0;
  width: 100%;

  font-family: Manrope;
  font-weight: 800;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  font-feature-settings: 'liga' off;

  /* Inside Auto Layout */
  flex: none;
  margin: 0px 0px;
}

.background_left-content_no-code-tagline {
  color: #8c30f5;
}
.background_left-content_button-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.background_left-content_join-waitlist {
  position: relative;
  margin-left: 10%;
  width: 196px;
  height: 62px;
  outline: none;
  border: none;
  cursor: pointer;
  /* Brand / Purple */
  background: #8c30f5;
  border-radius: 8px;
  border: none;
  transition: all 0.2s ease-out;

  /* Inside Auto Layout */
  flex: none;
  flex-grow: 0;

  /* Label / Large Label */
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  margin: 10px 0px;
  text-align: center;
  font-feature-settings: 'liga' off;

  /* Default / White */
  color: #ffffff;
}
@media screen and (min-width: 1000px) {
  .background_left-content_container {
    top: 10%;
    left: 3%;
    width: 50%;
    margin: 0px 0px;
  }
  .background_left-content_build-tagline,
  .background_left-content_no-code-tagline {
    font-size: 40px;
    line-height: 50px;
    text-align: left;
  }
  .background_left-content_button-container {
    justify-content: left;
    margin-top: 0px;
  }
  .background_left-content_join-waitlist {
    width: 160px;
    height: 50px;
    margin: 40px 0px;
  }
}
@media screen and (min-width: 1000px) {
  .background_left-content_container {
    top: 20%;
    left: 3%;
    width: 50%;
    margin: 40px 0px;
  }
  .background_left-content_build-tagline,
  .background_left-content_no-code-tagline {
    font-size: 72px;
    line-height: 98px;
  }
  .background_left-content_join-waitlist {
    width: 196px;
    height: 62px;
    margin: 40px 0px;
  }
}
