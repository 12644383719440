.functionality-container {
  /* Auto Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.functionality_section_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 60;
  margin-top: 20;
  align-items: center;
  padding-bottom: 10%;
  padding-top: 8%;
  padding-right: 3%;
  padding-left: 3%;
}
.functionality_left-content {
  /* Auto Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  order: 0;
}
.functionality_left-content_block {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.functionality_left-content_header {
  /* Watch tickers, Set triggers, Make money. */
  /* Spend less time watching the market */
  position: relative;
  padding-bottom: 5px;
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 35px;
  font-feature-settings: 'liga' off;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px;
}

.functionality_left-content_body {
  /* Select which indicators to use and tailor them to your investment thesis. */
  /* Automate your screening process by receiving realtime alerts whenever a ticker matches your set parameters. */

  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  font-size: 12px;
}

.functionality_right-content {
  position: relative;
}

.functionality_trading-bot-image {
  width: 360px;
  height: 180px;
}
@media screen and (min-width: 700px) {
  .functionality_left-content_header {
    font-size: 28px;
  }
  .functionality_trading-bot-image {
    width: 710px;
    height: 365px;
  }
}
@media screen and (min-width: 1200px) {
  .functionality_section_container {
    align-items: flex-start;
  }
  .functionality-container {
    /* Auto Layout */
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    text-align: left;
  }
  .functionality_left-content_block {
    padding-bottom: 20px;
  }
  .functionality_left-content_header {
    font-size: 60px;
    line-height: 60px;
  }
  .functionality_left-content_body {
    font-size: 20px;
    line-height: 36px;
  }
  .functionality_right-content {
    margin-top: 0%;
  }
}
@media screen and (min-width: 1285px) {
  .functionality_left-content_block {
    padding-bottom: 40px;
  }
  .functionality_left-content_header {
    font-size: 60px;
    line-height: 70px;
  }
  .functionality_left-content_body {
    font-size: 25px;
    line-height: 40px;
  }
  .functionality_trading-bot-image {
    width: 900px;
    height: 474px;
  }
  .functionality_right-content {
    margin-top: 0%;
  }
}
