.social-container {
  /* Auto Layout */
  background-image: url('../../../../assets/graphics/social-bg.png');
  width: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 1000px;
}
.social_left-content {
  position: relative;
  top: 15%;
  /* Auto Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 0;
}

.social_left-content_header {
  /* Connect your bot to anything */
  position: relative;
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 35px;
  padding-bottom: 20px;
  color: #ffffff;
  font-feature-settings: 'liga' off;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 1px;
}

.social_left-content_body {
  /* Whether your bot sends you texts or creates posts on social media, you’ll never miss a prediction. */
  position: relative;

  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  color: #ffffff;
  line-height: 20px;
  font-size: 12px;
  line-height: 20px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 32px 1px;
}

.social_left-content_icons {
  width: 180px;
  height: 27px;
}

.social_right-content_image {
  position: relative;
  top: 15%;
  width: 200px;
  height: 398px;
}

@media screen and (min-width: 450px) {
  .social_left-content_icons {
    width: 216px;
    height: 33px;
  }
  .social-container {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    text-align: left;
    padding-left: 5%;
  }
  .social_left-content {
    top: 30%;
    align-items: flex-start;
    margin-right: 35px;
  }
  .social_right-content_image {
    top: 30%;
    width: 200px;
    height: 380px;
    padding-right: 10%;
  }
}
@media screen and (min-width: 620px) {
  .social_left-content_header {
    font-size: 40px;
    line-height: 40px;
  }
  .social_left-content_body {
    line-height: 20px;
    font-size: 15px;
  }
}

@media screen and (min-width: 953px) {
  .social-container {
    padding-left: 10%;
    height: 1000px;
  }
  .social_left-content {
    /* Auto Layout */
    position: relative;
    top: 35%;
    margin-right: 35px;
  }
  .social_left-content_header {
    font-size: 60px;
    line-height: 60px;
  }
  .social_left-content_body {
    font-size: 20px;
    line-height: 36px;
  }
  .social_right-content_image {
    top: 20%;
    width: 400px;
    height: 761px;
    padding-right: 25%;
  }
  .social_left-content_icons {
    width: 360px;
    height: 54px;
  }
}

@media screen and (min-width: 1285px) {
  .social_left-content_header {
    font-size: 60px;
    line-height: 70px;
  }
  .social_left-content_body {
    font-size: 25px;
    line-height: 40px;
  }
}
