.background {
  background-image: url('../../../../assets/graphics/landing-bg.png');
  width: 100%;
  height: 700px;
  background-size: cover;
  padding-bottom: 100px;
  /* Auto Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}

@media screen and (min-width: 1000px) {
  .background {
    height: 1000px;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
}
