.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 70px;
}
.header_contact {
  position: relative;
  left: 15%;
  /* font details */
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 20px;
  margin: 10px 0px;
  font-feature-settings: 'liga' off;

  /* Text / Gray 900 */

  color: #18191f;
  flex-grow: 0;
}
.header_project-name {
  font-family: Manrope;
  font-style: normal;
  font-weight: 1000;
  font-size: 26px !important;
  line-height: 20px;
  margin: 10px 0px;

  color: #18191f;
  flex-grow: 0;
}
.header_project-logo {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  vertical-align: middle;
}
.header_project-logo img {
  width: 100%;
  margin: 0;
  padding: 0;
}
.header_join-waitlist {
  position: relative;
  right: 15%;
  width: 20%;
  /* Brand / Purple */
  background: #8c30f5;
  border-radius: 8px;

  /* Inside Auto Layout */

  display: flex;
  justify-content: center;
  align-items: center;

  /* Label / Medium Label */

  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  font-feature-settings: 'liga' off;

  /* Default / White */

  color: #ffffff;
}

.navbar_login_button-container {
  display: flex;
  justify-content: center;
}

.navbar_login_button {
  position: relative;
  width: '100%';
  height: 30px;
  outline: none;
  border: none;
  cursor: pointer;
  /* Brand / Purple */
  background: transparent;
  border-radius: 8px;
  border: none;

  /* Inside Auto Layout */
  flex: none;
  flex-grow: 0;

  /* Label / Large Label */
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  font-feature-settings: 'liga' off;
  /* Default / White */
  color: #8c30f5;
  padding: 5px;
}

.navbar_login {
  position: relative;
  justify-self: flex-start;
  margin-left: auto;
  padding: 20px;
}

.counterbalance {
  visibility: hidden;
  margin-right: auto;
  padding: 20px;
  width: 62px;
}
@media screen and (min-width: 450px) {
  .header {
    height: auto;
  }
}

@media screen and (min-width: 705px) {
  .header_contact {
    font-size: 14px;
    line-height: 20px;
    margin: 32px 0px;
  }
  .header_project-name {
    font-size: 30px;
    line-height: 20px;
    margin: 32px 0px;
  }
  .header_join-waitlist {
    font-size: 14px;
    line-height: 20px;
    margin: 14px 0px;
  }
}
