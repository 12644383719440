.footer-background {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 300px;
  background-size: cover;
  margin-top: 70px;
  margin-bottom: 80px;
  align-items: center;
}
.footer-header {
  /* Reserve your spot today */
  /* Heading / H2 */

  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 48px;
  /* identical to box height, or 133% */
  margin-right: 60px;
  margin-left: 60px;
  text-align: center;
  font-feature-settings: 'liga' off;
}

.footer-body {
  /* Get early access to our no-code trading bot workspace. */
  /* Lead / Lead 1 */

  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height, or 178% */

  text-align: center;
  font-feature-settings: 'liga' off;

  /* Default / White */

  color: #818181;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 25px 0px 25px;
}
.footer-button_container {
  display: flex;
  justify-content: center;
}
.footer-button {
  outline: none;
  border: none;
  cursor: pointer;
  position: relative;
  width: 196px;
  height: 62px;
  transition: all 0.2s ease-out;

  /* Brand / Purple */
  background: #8c30f5;
  border-radius: 8px;
  border: none;

  /* Inside Auto Layout */
  flex: none;
  flex-grow: 0;
  margin: 40px 0px;

  /* Label / Large Label */
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;

  text-align: center;
  font-feature-settings: 'liga' off;

  /* Default / White */
  color: #ffffff;
}

@media screen and (min-width: 450px) {
  .footer-header {
    font-size: 30px;
    line-height: 50px;
  }
  .footer-button {
    width: 160px;
    height: 50px;
    margin: 40px 0px;
  }
}

@media screen and (min-width: 695px) {
  .footer-header {
    font-size: 50px;
    line-height: 98px;
  }
  .footer-button {
    width: 196px;
    height: 62px;
    margin: 40px 0px;
  }
  .footer-body {
    font-size: 20px;
    line-height: 36px;
  }
}
@media screen and (min-width: 953px) {
  .footer-body {
    font-size: 25px;
    line-height: 40px;
  }
}
